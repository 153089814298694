import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from "@angular/core";
import {Voice} from "../../../../../shared/src/lib/services/speech/voice";
import {SpeechService} from "../../../../../shared/src/lib/services/speech/speech.service";
import {MatMenuTrigger} from "@angular/material/menu";

type CurrentItem = {
    audio: HTMLAudioElement,
    voiceId: number,
    progress: number,
};

@Component({
    selector: "app-voice-selector",
    templateUrl: "./voice-selector.component.html",
    styleUrls: ["./voice-selector.component.scss"]
})
export class VoiceSelectorComponent implements OnChanges {

    @Input()
    public voices: Array<Voice>;

    public voicesMap: Map<string, Array<Voice>> = new Map();

    @Input()
    public selectedVoices: Array<Voice>;

    @Output()
    public selectedVoicesChange = new EventEmitter<Array<Voice>>();

    @ViewChild(MatMenuTrigger)
    public menuTrigger: MatMenuTrigger;

    public voiceSystems = new Map([
        ["yandex", {name: "yandex", displayName: "Яндекс"}],
        ["google", {name: "google", displayName: "Google"}],
        // ["tinkoff", {name: "tinkoff", displayName: "Тинькофф"}],
    ]);

    public description = "Выберите один или несколько голосов озвучки, проигрывание будет в случайном порядке";

    public current: CurrentItem = {
        audio: null,
        voiceId: null,
        progress: 0,
    };

    public addDisabled = false;

    public replace = false;

    public constructor(private readonly speechService: SpeechService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.buildVoicesMap();
        this.addDisabled = this.selectedVoices?.length === this.voices?.length;
    }

    private buildVoicesMap() {
        for (const voiceType of this.voiceSystems.keys()) {
            this.voicesMap.set(voiceType, []);
        }

        for (const voice of this.voices) {
            if (this.selectedVoices.indexOf(voice) === -1) {
                this.voicesMap.get(voice.provider).push(voice);
            }
        }

        for (const voiceType of this.voiceSystems.keys()) {
            this.voicesMap.set(voiceType, this.voicesMap.get(voiceType).sort(VoiceSelectorComponent.compareVoices));
        }
    }

    private static compareVoices(v1: Voice, v2: Voice) {
        if (v1.localName < v2.localName) {
            return -1;
        }
        if (v1.localName > v2.localName) {
            return 1;
        }
        return 0;
    }

    public addVoice(voice: Voice) {
        this.stopPlaying();
        if (this.replace) {
            this.removeVoice(this.selectedVoices[0]);
            this.replace = false;
        }
        const voicesOfProvider = this.voicesMap.get(voice.provider);
        voicesOfProvider.splice(voicesOfProvider.indexOf(voice), 1);

        this.selectedVoices.push(voice);
        this.selectedVoicesChange.emit(this.selectedVoices);

        this.addDisabled = this.selectedVoices?.length === this.voices?.length;
        if (this.addDisabled) {
            this.menuTrigger.closeMenu();
        }
    }

    public replaceVoice() {
        this.replace = true;
        this.menuTrigger.openMenu();
    }

    public removeVoice(voice: Voice) {
        if (this.current.voiceId === voice.id) {
            this.stopPlaying();
        }
        this.selectedVoices.splice(this.selectedVoices.indexOf(voice), 1);
        this.selectedVoicesChange.emit(this.selectedVoices);
        this.voicesMap.get(voice.provider).push(voice);
        this.voicesMap.set(voice.provider, this.voicesMap.get(voice.provider).sort(VoiceSelectorComponent.compareVoices));
        this.addDisabled = false;
    }

    public togglePlay(voice: Voice) {
        if (this.current.voiceId) {
            this.stopPlaying();
        } else {
            this.playVoice(voice);
        }
    }

    public playVoice(voice: Voice) {
        if (this.current.audio) {
            this.stopPlaying();
        }
        this.current.voiceId = voice.id;

        const voiceOptions = {
            text: this.description,
            speed: 1,
        };
        const audio = this.speechService.buildVoiceAudio(voice.id, voiceOptions);
        audio.addEventListener("canplay", () => {
            this.current.audio = audio;
            this.current.audio.play();
        });
        const updateProgress = () => this.current.progress = 100 / audio.duration * audio.currentTime;
        audio.addEventListener("timeupdate", updateProgress);
        audio.addEventListener("pause", () => {
            audio.removeEventListener("timeupdate", updateProgress);
            this.current.progress = 0;
        });
        audio.addEventListener("ended", () => this.stopPlaying());
    }

    public stopPlaying() {
        if (this.current.audio) {
            this.current.audio.pause();
        }
        delete this.current.voiceId;
    }

}

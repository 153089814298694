<mat-card routerLink="/widgets/{{widget.getId()}}/edit"
          [id]="'widget-' + widget.getId()"
          (mouseenter)="showMenuButton = true"
          (mouseover)="showMenuButton = true"
          (mouseleave)="showMenuButton = false"
          (click)="showMenuButton = false"
          [ngClass]="widget.getType() | lowercase" matRipple>
    <mat-card-subtitle>
        <span>{{widget.getHeader() | uppercase}}</span>
        <button mat-icon-button
                [ngStyle]="{opacity: +(showMenuButton || menuOpened)}"
                [matMenuTriggerFor]="menu"
                (mousedown)="$event.stopPropagation()"
                (click)="$event.stopPropagation(); $event.preventDefault();"
                (onMenuOpen)="menuOpened = true"
                (onMenuClose)="menuOpened = false">
            <mat-icon>more_vert</mat-icon>
        </button>
    </mat-card-subtitle>
    <mat-card-title #tooltip="matTooltip"
                    (mousedown)="tooltip.hide()"
                    [matTooltip]="widget.getName().length > 30 ? widget.getName() : null"
                    [matTooltipPosition]="'above'"
                    [matTooltipShowDelay]="250">{{widget.getName()}}</mat-card-title>
    <mat-card-content>
        <div class="source-icons">
            <mat-icon *ngFor="let source of widget.getSources()"
                      svgIcon="source-{{source}}">
            </mat-icon>
        </div>
        <div>
            <span class="conditions" [innerText]="widget.getCondition()"></span>
            <span *ngIf="widget.isActive()" class="on-air">
                <mat-icon svgIcon="on-air"></mat-icon>
                <mat-icon svgIcon="on-air" class="glow"></mat-icon>
            </span>
        </div>
    </mat-card-content>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (mousedown)="onEditClick()">Редактировать</button>
        <button mat-menu-item (mousedown)="onCopyLinkClick()">Копировать ссылку</button>
        <button mat-menu-item (mousedown)="onTestClick()" *ngIf="widget.isAlertWidget()">Тест на стриме</button>
        <button mat-menu-item (mousedown)="onCopyClick()">Создать копию</button>
        <button mat-menu-item (mousedown)="onRemoveClick()">Удалить</button>
    </mat-menu>
</mat-card>
